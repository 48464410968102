import { Link } from "gatsby";
import React from "react";
import { Asset, NavigationList, PageLocale } from "../../../templates/Page/types";
import Burger from "../../../assets/svg/Burger.svg";
import BurgerClose from "../../../assets/svg/BurgerClose.svg";
import Logo from "../../atoms/Logo/Logo";
import "./Header.less";

export interface HeaderProps {
  locale: PageLocale;
  logo: Asset;
  brand: string;
  tagline: string;
  navigation: NavigationList[];
}

const Header = (props: HeaderProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="header__main">
        <div className="header__logo">
          <Logo
            locale={props.locale}
            image={props.logo}
            brand={props.brand}
            tagline={props.tagline}
          />
        </div>
        <div className="header__navigation header__navigation--desktop">
          {props.navigation.map((x, i) => (
            <div key={i} className="header__link">
              <Link to={x.url} title={x.title}>
                {x.label}
              </Link>
            </div>
          ))}
        </div>
        {isOpen ? (
          <BurgerClose className="header__burger" onClick={onToggle} />
        ) : (
          <Burger className="header__burger" onClick={onToggle} />
        )}
      </div>
      {isOpen && (
        <div className="header__navigation header__navigation--mobile">
          {props.navigation.map((x, i) => (
            <div key={i} className="header__link">
              <Link to={x.url} title={x.title}>
                {x.label}
              </Link>
            </div>
          ))}
        </div>
      )}
    </header>
  );
};

export default Header;
