import React from "react";
import "./PagePadding.less";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const PagePadding = (props: Props) => {
  let classNames = ["page-padding"];
  if (props.className) {
    classNames.push(props.className);
  }

  return <div className={classNames.join(" ")}>{props.children}</div>;
};

export default PagePadding;
