import React from "react";
import "./Logo.less";
import Image from "../../atoms/Image/Image";
import { Link } from "gatsby";
import { Asset, PageLocale, URL } from "../../../templates/Page/types";

interface Props {
  locale: PageLocale;
  brand: string;
  tagline: string;
  image: Asset;
}

const Logo = (props: Props) => {
  return (
    <Link className="logo" to={`/${props.locale}`}>
      <Image
        image={props.image}
        title={props.brand}
        className="logo__image"
      />
      <div className="logo__text">
        <strong>{props.brand}</strong>
        <br />
        {props.tagline}
      </div>
    </Link>
  );
};

export default Logo;
