import { NavigationList, RawNavigationList, SiteLocale } from "./types";

export const transformRawNavigation = (
  navigation: RawNavigationList[]
): NavigationList[] => {
  return navigation.map((x) => {
    return {
      label: x.title,
      url: `/${x.page.url.current}`,
      title: x.page.title,
      links: x.navigation.map((y) => ({
        label: y.title,
        title: y.page.title,
        url: `/${y.page.url.current}`,
      })),
    };
  });
};

export const sortLocales = (a: SiteLocale, b: SiteLocale) => {
  if (a.locale.current === "en") {
    return -1;
  }
  if (b.locale.current === "en") {
    return 1;
  }
  return 0;
};
