import { Link } from "gatsby";
import React from "react";
import { URL } from "../../../templates/Page/types";

interface Props {
  to: URL | string;
  children: React.ReactNode;
  isExternal?: boolean;
  title?: string;
  className?: string;
}

const CustomLink = (props: Props) => {
  const { to, children, isExternal, ...rest } = props;

  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="norefferer noopener" {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};

export default CustomLink;
