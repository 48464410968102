import React from "react";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { RawRichtext, RichtextLink } from "./types";
import "./Richtext.less";
import CustomLink from "../CustomLink/CustomLink";
import { URL } from "../../../templates/Page/types";

const BlockRenderer = (props: any) => {
  switch (props.node.style) {
    case "h1":
      return <h1>{props.children}</h1>;
    case "h2":
      return <h2>{props.children}</h2>;
    case "h3":
      return <h3>{props.children}</h3>;
    case "h4":
      return <h4>{props.children}</h4>;
    case "h5":
      return <h5>{props.children}</h5>;
    case "h6":
      return <h6>{props.children}</h6>;
    case "a":
      return <h6>{props.children}</h6>;
    case "blockquote":
      return <blockquote>{props.children}</blockquote>;
    case "code":
      return <code className="richtext__code">{props.children}</code>;
    default:
      return <p>{props.children}</p>;
  }
};

const LinkRenderer = (link: RichtextLink) => {
  return (
    <CustomLink
      to={
        (link.isExternal
          ? link.mark.href
          : `/${link.mark.href?.url?.current || ""}`) as URL
      }
      isExternal={link.isExternal}
    >
      {link.children}
    </CustomLink>
  );
};

interface Props {
  blocks: RawRichtext[];
  className?: string;
}

const Richtext = (props: Props) => {
  const classNames = ["richtext"];
  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div className={classNames.join(" ")}>
      <BlockContent
        blocks={props.blocks}
        serializers={{
          marks: {
            link: (x: Partial<RichtextLink>) =>
              LinkRenderer({ ...x, isExternal: true } as RichtextLink),
            internalLink: (x: Partial<RichtextLink>) =>
              LinkRenderer({ ...x, isExternal: false } as RichtextLink),
          },
          types: {
            block: (x: any) => BlockRenderer(x),
          },
        }}
      />
    </div>
  );
};

export default Richtext;
