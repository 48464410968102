import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Footer, { FooterProps } from "../Footer/Footer";
import Header, { HeaderProps } from "../Header/Header";
import "../../../styles/index.less";
import "./Layout.less";

interface Props {
  children: React.ReactNode;
  header: HeaderProps;
  footer: FooterProps;
}

const Layout = (props: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <div className="layout">
        <Header {...props.header} />
        <main className="layout__main">{props.children}</main>
        <Footer {...props.footer} />
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
