import { PageData, SiteSettings } from "../../../templates/Page/types";
import { transformRawNavigation } from "../../../templates/Page/utils";
import { FooterProps } from "../Footer/Footer";
import { HeaderProps } from "../Header/Header";

export const formatPageData = (site: SiteSettings, page: PageData) => {
  const header = page.locale.header;
  const newHeader: HeaderProps = {
    locale: page.locale.locale.current,
    brand: site.brand,
    tagline: site.tagline,
    logo: site.logo,
    navigation: transformRawNavigation(header.navigation),
  };
  const footer = page.locale.footer;
  const newFooter: FooterProps = {
    locale: page.locale.locale.current,
    brand: site.brand,
    tagline: site.tagline,
    logo: site.logo,
    pitch: footer._rawPitch,
    legal: footer._rawLegal,
    navigation: transformRawNavigation(footer.navigation),
  };

  return {
    title: page.title,
    description: page.description,
    ogImage: page.ogImage.asset.url,
    header: newHeader,
    footer: newFooter,
    locale: page.locale.locale.current,
    content: page.content,
  };
};
