import { Link } from "gatsby";
import React from "react";
import { Asset, NavigationList, PageLocale } from "../../../templates/Page/types";
import Logo from "../../atoms/Logo/Logo";
import Richtext from "../../atoms/Richtext/Richtext";
import { RawRichtext } from "../../atoms/Richtext/types";
import "./Footer.less";

export interface FooterProps {
  locale: PageLocale;
  logo: Asset;
  brand: string;
  tagline: string;
  pitch: RawRichtext;
  legal: RawRichtext;
  navigation: NavigationList[];
}

const Footer = (props: FooterProps) => {
  return (
    <div className="footer">
      <div className="footer__main">
        <div className="footer__row">
          <div className="footer__brand">
            <div className="footer__logo">
              <Logo
                locale={props.locale}
                image={props.logo}
                brand={props.brand}
                tagline={props.tagline}
              />
            </div>
            <div className="footer__pitch">
              <Richtext blocks={props.pitch} />
            </div>
          </div>
          <div className="footer__navigation">
            {props.navigation.map((x, i) => (
              <div key={i} className="footer__section">
                <div className="footer__section-title">
                  <Link to={x.url} title={x.title}>
                    {x.label}
                  </Link>
                </div>
                <ol className="footer__section-list">
                  {x.links.map((y, i) => (
                    <li key={i} className="footer__section-item">
                      <Link to={y.url} title={y.title}>
                        {y.label}
                      </Link>
                    </li>
                  ))}
                </ol>
              </div>
            ))}
          </div>
        </div>
        <div className="footer__row">
          <div className="footer__legal">
            <Richtext blocks={props.legal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
