import React from "react";
import Img, { GatsbyImageOptionalProps } from "gatsby-image";
import { Asset, ObjectFit } from "../../../templates/Page/types";

import "./Image.less";

type ImageSize = 135 | 270 | 540 | 720 | 1080 | 1280 | 1440 | 1920;
interface Props {
  image: Asset;
  title: string;
  objectFit?: ObjectFit;
  sizes?: ImageSize[];
  className?: string;
}

const Image = ({ image, objectFit, sizes, ...props }: Props) => {
  const optionalProps: GatsbyImageOptionalProps = {};
  if (objectFit && objectFit !== "cover") {
    optionalProps.imgStyle = {
      objectFit,
    };
  }
  const imageSizes = sizes || [270, 540, 720, 1080, 1280, 1440, 1920];
  // add ar_1.77777,c_crop to enforce aspect ratio crop
  const fluidCloudinary = {
    base64: image.asset.fluid.base64,
    aspectRatio: image.asset.fluid.aspectRatio,
    src: `https://res.cloudinary.com/${process.env.GATSBY_CLOUNDINARY_ID}/image/fetch/f_auto/${image.asset.url}`,
    srcSet: imageSizes
      .map(
        (size) =>
          `https://res.cloudinary.com/${process.env.GATSBY_CLOUNDINARY_ID}/image/fetch/c_limit,f_auto,w_${size}/${image.asset.url} ${size}w`
      )
      .join(",\n"),
    sizes: "(max-width: 800px) 100vw, 800px",
  };

  return <Img fluid={fluidCloudinary} {...optionalProps} {...props} />;
};

Image.defaultProps = {
  title: "",
};

export default Image;
