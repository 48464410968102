import React from "react";
import "./Section.less";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Section = (props: Props) => {
  let classNames = ["section"];
  if (props.className) {
    classNames.push(props.className);
  }

  return <section className={classNames.join(" ")}>{props.children}</section>;
};

export default Section;
